import React from "react";

const ContactUs = () => {
  return (
    <div className="flex flex-col h-full gap-2 p-4 md:px-12 md:pt-10">
      <div className="text-white rounded-2xl bg-dark-bl-clr">
        <div className="p-8">
          <h1 className="mb-4 text-2xl font-bold">Contact Us</h1>
          <p className="mt-4">
            You may contact us using the information below:
          </p>
          <ul className="mt-2 list-none">
            <li className="mt-2">
              <strong>Merchant Legal Entity Name:</strong> Ghist Technologies
            </li>
            <li className="mt-2">
              <strong>Registered Address:</strong> 2ND STAGE, INDIRANAGAR 13TH
              CROSS PARK, NO 235, BENGALURU, BENGALURU URBAN, KARNATAKA, 560038,
              BANGALORE NORTH, Karnataka, PIN: 560038
            </li>
            <li className="mt-2">
              <strong>Operational Address:</strong> 2ND STAGE, INDIRANAGAR 13TH
              CROSS PARK, NO 235, BENGALURU, BENGALURU URBAN, KARNATAKA, 560038,
              BANGALORE NORTH, Karnataka, PIN: 560038
            </li>
            <li className="mt-2">
              <strong>Telephone No:</strong> 7891764101
            </li>
            <li className="mt-2">
              <strong>E-Mail ID:</strong>{" "}
              <a
                href="mailto:support@scorebadhao.com"
                className="underline text-golden"
              >
                support@scorebadhao.com
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
